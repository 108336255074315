import { useState, useEffect } from "react";

import DetailsForm from "./DetailsForm";
import CaptureImage from "./CaptureImage";
import UploadImage from "./UploadImage";
import { useUser } from "../../../Store/user";
import { loginFaceReg } from "../../../apis/events";
import { useEvent } from "../../../Store/event";
import LoadingGif from "../../../assets/images/loading.gif";
import Cameramodule from "../../../pages/RegisterFace/Cameramodule";
import RegisterStatus from "../../../pages/FaceRec/RegisterStatus";
import { checkAccess } from "../../../apis/facesearch";
import {
  EMAIL_WHERE_DIRECT_FACE_REC,
  EVENT_WITH_DIRECT_FACE_REC,
  MAX_FACE_REG_REACHED_POPUP,
  MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
  MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
} from "../../../constants";

function generateRandom6DigitNumber() {
  const randomNumber = Math.floor(Math.random() * 1000000);
  const paddedNumber = randomNumber.toString().padStart(6, "0");
  return paddedNumber;
}

const DIMESNIONS = {
  width: 533.33,
  height: 400,
};

const FaceSearchModal = (props) => {
  const { close, isCamera, setIsCamera, loading, setLoading } = props;

  const eventDetails = useEvent().response;
  const { user, setUser } = useUser();

  const [showPreReg, setShowPreReg] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [apiLoader, setApiLoader] = useState(true);

  const registerUser = async () => {
    try {
      if (!user.pin) user.pin = eventDetails.pin;
      const res = await loginFaceReg(eventDetails.data.id, user);
      if (!res.error) {
        setUser((prev) => ({ ...prev, id: res.response.data.id }));
      } else {
        if (
          [
            MAX_FACE_SEARCH_LIMIT_REACH_BE_MESSAGE,
            MAX_GUEST_LIMIT_REACH_BE_MESSAGE,
          ].includes(res.message)
        ) {
          MAX_FACE_REG_REACHED_POPUP(!!eventDetails.data.isPublished);
          close();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user.fname && user.lname && user.email && user.accessToken) {
      registerUser();
    }
  }, []);

  const isUserAllowed = async () => {
    try {
      setApiLoader(true);
      const res = await checkAccess(eventDetails.photographer.id);
      if (!res.error) setHasAccess(res?.response?.faceSearchAccess);
      if (EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id) && !user.accessToken) {
        const pin = eventDetails.pin || 1284;
        let newUser = {
          ...user,
          fname: generateRandom6DigitNumber(),
          lname: generateRandom6DigitNumber(),
          email: `${generateRandom6DigitNumber()}_${
            EMAIL_WHERE_DIRECT_FACE_REC[eventDetails.data.id]
          }`,
          pin: pin,
          isFaceSearch: true,
          photographerId: eventDetails.photographer.id,
        };
        let result = await loginFaceReg(eventDetails.data.id, newUser);
        if(result.error){
          window.Toast.fire({
            title: "Something went wrong",
            icon: "error"
          })
          return
        }
        setUser((prev) => ({
          ...prev,
          email: newUser.email,
          accessToken: result.response.data.accessToken,
          isAdmin: result.response.data.isSuperAdmin,
          isValid: true,
          pin: eventDetails.pin ? eventDetails.pin : "",
          fname: newUser.fname,
          lname: newUser.lname,
          id: result.response.data.guestId,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setApiLoader(false);
    }
  };

  useEffect(() => {
    isUserAllowed();
  }, []);

  useEffect(() => {
    let status;
    const permissionChangeListener = (evt) => {
      setLoading(false);
      if (evt.currentTarget.state === "denied") setIsCamera(false);
      else setIsCamera(true);
    };

    (async () => {
      try {
        if (navigator.userAgent.search("Firefox") > -1 && isCamera) {
          setLoading(false);
        } else {
          if (navigator.permissions && navigator.permissions.query) {
            status = await navigator.permissions.query({ name: "camera" });
            if (status.state === "denied") {
              setIsCamera(false);
              navigator.mediaDevices
                .getUserMedia({
                  video: DIMESNIONS,
                  facingMode: { exact: "environment" },
                })
                .then((stream) =>
                  stream.getTracks().map((track) => track.stop())
                );
              setLoading(false);
            } else setLoading(false);
            status.addEventListener("change", permissionChangeListener);
          } else setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    return () => {
      status.addEventListener("change", permissionChangeListener);
      setLoading(false);
    };
  }, []);

  return loading || apiLoader ? (
    <div>
      <img className="m-auto" src={LoadingGif} width={150} height={150} />
    </div>
  ) : hasAccess ? (
    showPreReg ? (
      <RegisterStatus isFullAccess />
    ) : (user.fname && user.lname && user.email && user.accessToken) ||
      (user.fname &&
        user.lname &&
        user.email &&
        !eventDetails.data.isPublished) ? (
      eventDetails.data.isLivenessEnabled ? (
        <Cameramodule isFullAccess={true} close={close} />
      ) : isCamera ? (
        <CaptureImage
          setLoading={setLoading}
          loading={loading}
          isCamera={isCamera}
          setIsCamera={setIsCamera}
          close={close}
          setShowPreReg={setShowPreReg}
        />
      ) : (
        <UploadImage
          setLoading={setLoading}
          close={close}
          setShowPreReg={setShowPreReg}
        />
      )
    ) : (
      <DetailsForm setLoading={setLoading} close={close} />
    )
  ) : (
    <div className="flex justify-center items-center ">
      <h1 className="text-base md:text text-dark-gray1 pb-4 dark:text-light-gray">
        This is disabled.
      </h1>
    </div>
  );
};

export default FaceSearchModal;
