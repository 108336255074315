import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import CreateNewFolder from "../../../../components/common/CreateNewFolder";
import ExistingFolder from "../../../../components/common/ExistingFolder";
import GoBackSection from "../../../../components/common/GoBackSection";
import { useEvent } from "../../../../Store/event";
import { useFavourites } from "../../../../Store/favourites";
import GalleryHeading from "../GalleryHeading";
import { useUser } from "../../../../Store/user";
import { useFolder } from "../../../../Store/folder";

const FavoriteFolderDrawer = ({
  changeGallery,
  onFolderClickHandler,
  onFavoriteFolderClickHandler,
  isFoldersDrawerVisible,
}) => {
  let { favList } = useFavourites();
  let { user } = useUser();
  let { folders } = useFolder();
  let navigate = useNavigate();
  const { slug } = useParams();
  const eventDetails = useEvent().response;
  const search = useLocation().search;
  const searchId = new URLSearchParams(search).get("searchId");
  const galleryViewPath = `/view/${slug ? `${slug}/` : ""}${
    eventDetails.data.slug
  }/${folders[0]?.slug}`;
  const backLink = searchId
    ? `/facerec/${eventDetails.data.slug}/results/${searchId}`
    : galleryViewPath;

  useEffect(() => {
    if (!(user.email || user.phone)) {
      changeGallery(false);
      navigate(galleryViewPath);
    }
  }, []);
  return (
    <div className="mx-auto px-[18px] md:px-10 bg-theme-light dark:bg-dark-gray text-dark-gray1 dark:text-light-gray pb-[4.063rem]">
      <GalleryHeading
        onFolderClickHandler={onFolderClickHandler}
        isMobileView
        changeGallery={changeGallery}
        isFoldersDrawerVisible={isFoldersDrawerVisible}
      />
      <GoBackSection
        changeGallery={changeGallery}
        onFolderClickHandler={onFolderClickHandler}
        backLink={backLink}
      />
      <div className="mt-[30px] grid gap-4 grid 3xl:grid-cols-6 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3">
        <CreateNewFolder size="36" />
        {favList.length ? (
          favList.map((folder, i) => (
            <ExistingFolder
              key={i}
              isMobileView
              folder={folder}
              onFavoriteFolderClickHandler={onFavoriteFolderClickHandler}
              folderNumber={i}
            />
          ))
        ) : (
          <></>
        )}
        {/* <ExistingFolder key='folder-2' isMobileView /> */}
      </div>
    </div>
  );
};

export default FavoriteFolderDrawer;
