import "./retakePhoto.css";
import Swal from "sweetalert2";
import group from "../../assets/images/group.png";
import photo from "../../assets/images/photo.png";
// import StaticLogo from "../../components/common/StaticLogo";
// import GoBackSection from "../../components/common/GoBackSection";
import Button from "../../components/common/Button";
import BackButton from "../../components/common/Backbutton";
// import CloseIcon from "../../assets/icons/CloseIcon";
// import { ThemeContext } from "../../components/App";
import { useEffect, useRef } from "react";
import { useCallback } from "react";
import { useUser } from "../../Store/user";
import { useEvent } from "../../Store/event";
import { faceSearch, faecResults } from "../../apis/facesearch";
import { useState } from "react";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useContext } from "react";
import { ThemeContext } from "../../components/App";
import { Link, Navigate } from "react-router-dom";
import {
  PHOTOGRAPHER_WITH_DIRECT_FACE_REC,
  FACE_SEARCH_NO_RESULT,
  REFRESH_LOCAL_STORAGE,
  EVENT_WITH_SPECIAL_DIGITAL_PURCHASE,
  MAX_UPLOAD_IMAGE_SIZE,
  EVENT_WITH_DIRECT_FACE_REC,
  PHOTOGRAPHER_WITH_FACE_REC_ACCESS,
  FACE_SEARCH_NO_RESULT_CUSTOM,
  PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE,
} from "../../constants";
import { checkIsDeviceIOS } from "../../helper";

const RetakePhoto = ({ setLoading, setShowPreRegSuccess, setIsCamera }) => {
  const { user, setUser } = useUser();
  const [searchId, setSearchId] = useState(null);
  const eventDetails = useEvent().response;
  const inputRef = useRef();
  const imageRef = useRef();
  const theme = useContext(ThemeContext);
  const isDarkMode = theme === "dark";

  const isDeviceIOS = checkIsDeviceIOS();

  const handleFileUploadChange = useCallback(async (e) => {
    /** File is greater than 10mb */
    if (
      e.target.files[0].size &&
      e.target.files[0].size > MAX_UPLOAD_IMAGE_SIZE
    ) {
      window.Toast.fire({
        icon: "error",
        title: "Image size is more than 6 MB",
      });
      return;
    }
    var FR = new FileReader();
    FR.addEventListener("load", async function (e) {
      imageRef.current.src = e.target.result;
      try {
        setLoading(true);
        let result = await faceSearch(
          user.id,
          eventDetails.data.id,
          e.target.result,
          eventDetails.data.isPublished,
          user?.previousImages
        );
        if (result.error) {
          if (
            result.message === "Unsupported image format is sent" ||
            result.message === "Decoding of image failed"
          ) {
            window.Toast.fire({
              icon: "error",
              title: result.message,
            });
            setLoading(false);
            return;
          }
          let swalContent = FACE_SEARCH_NO_RESULT;
          if (
            PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
              eventDetails.photographer.id
            ) ||
            PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE.includes(
              eventDetails.photographer.id
            )
          ) {
            swalContent =
              FACE_SEARCH_NO_RESULT_CUSTOM[eventDetails.photographer.id];
          }
          Swal.fire(swalContent).then(function (response) {
            //setTimeout(window.location.reload(true));

            if (
              PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                eventDetails.photographer.id
              ) ||
              PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
                eventDetails.photographer.id
              ) ||
              EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
            )
              setLoading(false);
            else setUser({ ...user, id: 0 });
          });
        } else if (result.response.status === 1 && result.response.search_id) {
          setSearchId(result.response.search_id);
          EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(eventDetails.data.id) &&
            localStorage.removeItem(REFRESH_LOCAL_STORAGE);
          if (
            EVENT_WITH_SPECIAL_DIGITAL_PURCHASE.includes(
              eventDetails.data.id
            ) &&
            isDeviceIOS
          )
            setUser({ ...user, search_id: result.response.search_id });
          // let imgs = await faecResults(result.response.search_id, eventDetails.data.id)
          // console.log("imgs",imgs)
          // setUser({...user, images: imgs.response.data})
          // setImages(imgs.response.data)
        } else if (result.response.status === 2) {
          setShowPreRegSuccess(true);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        let swalContent = FACE_SEARCH_NO_RESULT;
        if (
          PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
            eventDetails.photographer.id
          ) ||
          PHOTOGRAPHER_WITH_CUSTOM_FS_MESSAGE.includes(
            eventDetails.photographer.id
          )
        ) {
          swalContent =
            FACE_SEARCH_NO_RESULT_CUSTOM[eventDetails.photographer.id];
        }
        Swal.fire(swalContent).then(function (response) {
          //setTimeout(window.location.reload(true))
          if (
            PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
              eventDetails.photographer.id
            ) ||
            PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
              eventDetails.photographer.id
            ) ||
            EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id)
          )
            setLoading(false);
          else setUser({ ...user, id: 0 });
        });
      }
    });
    FR.readAsDataURL(e.target.files[0]);
  });
  useEffect(async () => {
    const status = await navigator.permissions.query({ name: "camera" });
    status.addEventListener(
      "change",
      (evt) => {
        setIsCamera(true);
      },
      { once: true }
    );
  });
  const showRetakeToast = () => {
    Swal.fire(
      "Need Permissions",
      "Please allow browser to access camera",
      "warning"
    );
  };
  return (
    <>
      {searchId !== null ? (
        <Navigate
          to={`/facerec/${eventDetails.data.slug}/results/${searchId}`}
        />
      ) : (
        <div className="min-h-screen">
          {EVENT_WITH_DIRECT_FACE_REC.includes(eventDetails.data.id) ? <div className="hidden sm:block px-[24px] md:px-10 pt-[1.875rem] text-[#00b7b8] text-base font-semibold underline"></div> : 
          <div className="hidden sm:block px-[24px] md:px-10 pt-[1.875rem] text-[#00b7b8] text-base font-semibold underline">
            {" "}
            &lt;{" "}
            <span
              className="cursor-pointer"
              onClick={() => {
                setUser({ ...user, id: 0 });
              }}
            >
              Go Back
            </span>
          </div>}
          <div className="md:px-10 md:pt-10 p-6 items-center">
            <div className="mx-auto lg:border lg:border-retake-light-border dark:border-retake-dark-border flex justify-center items-center self-center lg:pl-[6.875rem] lg:pr-[3.875rem] xl:pl-[12.875rem] xl:pr-[18.875rem]">
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-2 lex justify-center items-center gap-6">
                <div className="flex justify-center">
                  <div className="">
                    <div className="hidden lg:block">
                      <img src={group} alt="retake photo" />
                    </div>
                    {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                      eventDetails.photographer.id
                    ) ||
                    PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
                      eventDetails.photographer.id
                    ) ||
                    EVENT_WITH_DIRECT_FACE_REC.includes(
                      eventDetails.data.id
                    ) ? (
                      <></>
                    ) : (
                      <div className="flex justify-end text-neutral-800 dark:text-slate-50 p-5 md:hidden lg:hidden block mb-5">
                        <span
                          onClick={() => {
                            setUser({ ...user, id: 0 });
                          }}
                        >
                          <CloseIcon fill={`${isDarkMode ? "#eee" : "#222"}`} />
                        </span>
                      </div>
                    )}
                    <div className="text-2xl text-neutral-800 dark:text-slate-50 lg:mt-6 text-center md:text-left p-[10px] font-medium text-lg md:w-[31.063rem]">
                      We can’t seem to access your camera, try again or upload a
                      picture.
                    </div>
                  </div>
                </div>
                <div className="flex justify-center lg:justify-end">
                  <div className="lg:pb-8 lg:mt-12 ">
                    <div
                      className="border-dashed border rounded-[100%] dark:border-slate-600 oval-shape cursor-pointer dark:shadow-dark-input shadow-dark-input-bottom-right md:pt-[105px] md:pb-[105px] md:pl-[42px] md:pr-[41px]"
                      onClick={() => {
                        inputRef.current.click();
                      }}
                    >
                      <div className="flex justify-center mt-[50%] md:m-0">
                        <img
                          ref={imageRef}
                          src={photo}
                          width="60"
                          text-align="center"
                          height="60"
                          alt="drag & drop your photo"
                        />
                      </div>
                      {/* <div className='text-center text-sm text-neutral-800 dark:text-slate-50 pt-5 hidden lg:block'>
                                        Drag & drop your photo
                                    </div> */}
                      <div className="underline text-sm font-semibold text-cyan text-center pt-5 hidden lg:block">
                        Browse
                      </div>
                    </div>
                    <div className="text-sm font-semibold text-neutral-800 dark:text-slate-50 text-center flex justify-center cursor-pointer lg:hidden block">
                      <input
                        ref={inputRef}
                        accept="image/*"
                        type="file"
                        className="hidden"
                        onChange={handleFileUploadChange}
                      />
                      <Button
                        text="Upload Photo"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                        className="w-[150px] h-[40px]"
                      />
                    </div>
                    {PHOTOGRAPHER_WITH_DIRECT_FACE_REC.includes(
                      eventDetails.photographer.id
                    ) ||
                    PHOTOGRAPHER_WITH_FACE_REC_ACCESS.includes(
                      eventDetails.photographer.id
                    ) ||
                    EVENT_WITH_DIRECT_FACE_REC.includes(
                      eventDetails.data.id
                    ) ? (
                      <></>
                    ) : (
                      <div className="p-2 pt-5 text-sm font-semibold text-center flex justify-center cursor-pointer">
                        <BackButton
                          onClick={showRetakeToast}
                          text="Retake Photo"
                          className="w-[150px] h-[40px] md:w-[10.875rem]"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RetakePhoto;
