import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getFolderDetails } from "../apis/events";
import { useEvent } from "./event";
import { useUser } from "./user";
import { flag } from "../apis/favourites";
import { faecResults, getFaceResultWithFolders } from "../apis/facesearch";
import {
  EVENT_WITH_FACE_SEARCH_FOLDER,
  MY_PHOTOS,
  PHOTOGRAPHER_WITH_FACE_SEARCH_FOLDER,
} from "../constants";
import Swal from "sweetalert2";

const Folder = React.createContext();
Folder.displayName = "Folder";

export const useFolder = () => React.useContext(Folder);

export const FolderProvider = ({ children }) => {
  const eventDetails = useEvent().response;
  const { user, setUser } = useUser();
  const params = useParams();
  const navigate = useNavigate();
  const route = useLocation();

  let { slug } = params;

  if (eventDetails.data.stub) slug = eventDetails.data.stub;

  const [folder, setFolder] = useState({});
  const [folders, setAllFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState({});
  const [loading, setLoading] = useState(true);
  const [myPhotos, setMyPhotos] = useState([]);
  const [maxPage, setMaxPage] = useState("");
  const [count, setCount] = useState(0);
  const [faceSearchFolders, setFaceSearchFolders] = useState([]);
  const [currentFaceSearchFolder, setCurrentFaceSearchFolder] = useState(null);

  const controller = useRef(new AbortController());

  const setMyPhotosFolder = (searchId) => {
    if (searchId) {
      setAllFolders((prev) => {
        const myPhotoIndex = prev.findIndex((f) => f.name === "My Photos");
        if (myPhotoIndex > -1) {
          const myPhotosFolderCopy = prev.splice(myPhotoIndex, 1)[0];
          myPhotosFolderCopy.id = searchId;

          return [myPhotosFolderCopy, ...prev];
        } else {
          const myPhotosFolder = {
            ...prev[0],
            awsFolderName: "",
            id: searchId,
            name: "My Photos",
            slug: `my-photos`,
            publicImagesCount: 10, // Change this when we get the images
            imagesCount: 10,
          };
          return [myPhotosFolder, ...prev];
        }
      });
    }
  };
  const removeMyPhotosFolder = () => {
    if (folders[0].name === "My Photos") {
      const newAllFolders = [...folders].slice(1);
      setAllFolders(newAllFolders);
    }
  };

  const addUserDetails = (user) => {
    setUser((prev) => ({
      ...prev,
      email: user.email,
      id: user.id,
      accessToken: user.access_token,
      fname: user.name?.split(" ")[0],
      lname: user.name?.split(" ")[1],
      phone: user?.phone,
    }));
  };

  const changeFolderData = async () => {
    if (
      route.pathname.includes("/facerec/") === false &&
      route.pathname.includes("/favourites/") === false &&
      route.pathname.includes("/face-patches/") === false &&
      folders.length > 0
    ) {
      controller.current.abort();
      controller.current = new AbortController();
      const allFolders = [...folders.filter((f) => f.imagesCount > 0)];
      let activeFolder;
      if (params.folderName) {
        activeFolder = [
          ...allFolders.filter((f) => f.id && f.slug === params.folderName),
        ][0];
      }
      if (!allFolders.length) {
        setLoading(false);
        return;
      }
      if (
        !activeFolder &&
        route.pathname.includes("/facerec/") === false &&
        route.pathname.includes("/favourites/") === false &&
        route.pathname.includes("/face-patches/") === false
      ) {
        activeFolder = allFolders[0];
        console.log("Inside Navigate");
        navigate(
          `/view/${slug ? `${slug}/` : ""}${eventDetails.data.slug}/${
            activeFolder.slug
          }`
        );
        return;
      }
      try {
        setLoading(true);
        let folderImages;
        if (activeFolder?.slug === "my-photos") {
          if (
            EVENT_WITH_FACE_SEARCH_FOLDER.includes(eventDetails.data.id) ||
            PHOTOGRAPHER_WITH_FACE_SEARCH_FOLDER.includes(
              eventDetails.photographer.id
            )
          ) {
            folderImages = await getFaceResultWithFolders(
              activeFolder.id,
              currentFaceSearchFolder?.id
            );
            if (folderImages.response.folders.length)
              setFaceSearchFolders(folderImages.response.folders);
            if (!currentFaceSearchFolder)
              setCurrentFaceSearchFolder(folderImages.response.folders[0]);
          } else {
            folderImages = await faecResults(
              activeFolder.id,
              eventDetails.data.id
            );
          }
          if (folderImages.response.allImagesPrivate) {
            Swal.fire({
              icon: "warning",
              text: "Nice smile! We are yet to upload your images!! The one we found are private or deleted.",
            }).then(removeMyPhotosFolder);
          }
        } else {
          folderImages = await getFolderDetails(
            activeFolder.id,
            user,
            activeFolder.imageSortCondition,
            activeFolder.imageSortField,
            controller.current.signal,
            1
          );
          setCurrentFaceSearchFolder(null);
        }
        if (folderImages.error) {
          setUser({ ...user, isValid: false, error: "Invalid Password" });
          if (typeof folderImages.message !== "undefined") {
            window.Toast.fire({
              title: folderImages.message,
              icon: "error",
            });
            setUser((prev) => ({ ...prev, password: "", isValid: false }));
          }
        } else {
          if (!user.isValid) setUser({ ...user, isValid: true });
          activeFolder.images = folderImages.response.data;
          if (activeFolder?.slug === "my-photos") {
            // activeFolder.imagesCount = folderImages.response.data.length;
            // activeFolder.publicImagesCount = folderImages.response.data.length;
            setMyPhotos(folderImages.response.data);
            !user.email && addUserDetails(folderImages.response.guest);
            setMaxPage(folderImages.response?.meta?.last_page);
            setCount(folderImages.response?.meta?.count);
          } else {
            setMaxPage(folderImages.response?.meta?.last_page);
            setCount(folderImages.response?.meta?.count);
          }
        }
      } catch (e) {
        /** Error thrown when we abort the request */
        if (e.message === "Cancel: canceled") {
          return;
        } else {
          console.log("Error in Folders", e);
          setUser({ ...user, isValid: false, error: "Something Went Wrong" });
          setLoading(false);
        }
      }
      setActiveFolder(structuredClone(activeFolder));
    }
  };

  useEffect(() => {
    if (folders.length) {
      if (eventDetails.data.requiresEmail && (user.accessToken || user.jwt)) {
        changeFolderData();
      } else if (
        !eventDetails.data.requiresEmail &&
        !eventDetails.data.requiresPassword
      ) {
        changeFolderData();
      } else if (eventDetails.data.requiresPassword && user.password) {
        changeFolderData();
      } else if (user.search_id && folders[0].slug === MY_PHOTOS.slug) {
        changeFolderData();
      }
    }
  }, [
    folders,
    params.folderName,
    params.eventName,
    user.password,
    user.accessToken,
  ]);

  const setPrivacy = async (photoId, folderId, visibility) => {
    let res = await flag(
      photoId,
      folderId,
      visibility,
      user,
      eventDetails.data.id
    );
    if (!res.error) {
      window.Toast.fire({
        icon: "success",
        title: visibility == 0 ? "Photo is now private" : "Photo is now public",
      });
      const index = activeFolder.images.findIndex((f) => f.id == photoId);
      var newFolder = { ...activeFolder };
      newFolder.images[index].isPrivate = visibility = +!visibility;
      setActiveFolder(newFolder);
    } else {
      window.Toast.fire({
        icon: "error",
        title: res.message,
      });
    }
  };

  useEffect(() => {
    setAllFolders(
      eventDetails.data?.folders.filter((el) =>
        user.isAdmin ? true : !el.showOnlyOnFaceSearch
      )
    );
  }, [eventDetails.data?.folders, user.isAdmin]);

  useEffect(() => {
    if (user.search_id) {
      setMyPhotosFolder(user.search_id);
      if (
        EVENT_WITH_FACE_SEARCH_FOLDER.includes(eventDetails.data.id) ||
        PHOTOGRAPHER_WITH_FACE_SEARCH_FOLDER.includes(
          eventDetails.photographer.id
        )
      ) {
        if (currentFaceSearchFolder) setCurrentFaceSearchFolder(null);
        if (faceSearchFolders.length) setFaceSearchFolders([]);
      }
      if (params.folderName === MY_PHOTOS.slug) {
        setActiveFolder((prev) => ({
          ...prev,
          id: user.search_id,
        }));
      }
    }
  }, [user.search_id]);

  useEffect(() => {
    if (currentFaceSearchFolder) {
      changeFolderData();
    }
  }, [currentFaceSearchFolder]);

  return (
    <Folder.Provider
      value={{
        folder: activeFolder,
        setFolder,
        setActiveFolder,
        setPrivacy,
        loading,
        folders,
        removeMyPhotosFolder,
        setAllFolders,
        changeFolderData,
        myPhotos,
        setMyPhotos,
        setLoading,
        maxPage,
        currentFaceSearchFolder,
        faceSearchFolders,
        setCurrentFaceSearchFolder,
        count,
      }}
    >
      {children}
    </Folder.Provider>
  );
};
